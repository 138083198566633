import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { useJsonToCsv } from 'react-json-csv';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  Input,
  Table,
  Select,
  DatePicker,
  Popover,
  Spin,
  Switch,
  Avatar,
  notification,
  Tooltip,
  Button
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import bookingApi from '../../../../api/bookingApi';
import envConfig from '../../../../envConfig';
import {
  eventTypes,
  getEventTypeName,
  percent,
  getEventBookingStatus,
  formatPhoneNumber
} from '../../../utils/functions';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { timezoneAtom, viewDetailsAtom } from '../../../../atoms/Atoms';
import EventDetails from '../../../shared-components/modals/EventDetailsModal';
import { dateRangePresets } from '../../../utils/utils';

function BookingsHistory() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t } = useTranslation();
  const [dates, setDates] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [hostFilter, setHostFilter] = useState(null);
  const [hosts, setHosts] = useState([]);
  const [locationFilter, setLocationFilter] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [includeTimeslots, setIncludeTimeslots] = useState(false);
  const [loadingHosts, setLoadingHosts] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const [viewDetails, setViewDetails] = useRecoilState(viewDetailsAtom);

  const participantsListFields = {
    userFirstName: t('first_name'),
    userLastName: t('last_name'),
    userPhone: t('phone'),
    userEmail: t('email'),
    status: t('status'),
    createdAt: t('created_on')
  };

  const eventListFields = {
    eventName: t('name'),
    totalSlots: t('total_slots'),
    totalBookings: t('bookings'),
    totalPayments: t('payments'),
    totalSales: t('sales'),
    fillPercentage: t('fill_rate'),
    vacancyPercentage: t('vacancy_rate')
  };

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getLocations() {
    setLoadingLocations(true);
    bookingApi
      .get('locations')
      .then((response) => {
        setLoadingLocations(false);
        setLocations(response.data.data);
      })
      .catch((error) => {
        setLoadingLocations(false);
        console.error(error);
      });
  }

  function getHosts() {
    setLoadingHosts(true);
    bookingApi
      .get('hosts')
      .then((response) => {
        setLoadingHosts(false);
        setHosts(response.data.data);
      })
      .catch((error) => {
        setLoadingHosts(false);
        console.error(error);
      });
  }

  function getData(force) {
    if (!loading || force) {
      setLoading(true);
      const payload = {
        EventType: typeFilter,
        LocationId: locationFilter,
        HostId: hostFilter,
        IncludeTimeslots: includeTimeslots,
        StartDate: moment(dates?.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('YYYY-MM-DDTHH:mm:00.000'),
        EndDate: moment(dates?.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').add(1, 'days').format('YYYY-MM-DDTHH:mm:00.000'),
      };
      bookingApi.post('admin/bookings/report', payload)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setReportData(response.data?.data);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error.message);
        });
    }
  }

  useEffect(() => {
    if (firstLoad && dates) {
      setFirstLoad(false);
      getData(true);
    }
  }, dates);

  useMount(() => {
    setDates({
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    });
    getLocations();
    getHosts();
  });

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('bookings_report')}
      />
      <div className="flex space-x-2">
        <div>
          <strong>{t('event_type')}:</strong>
          <br />
          <Select
            style={{ width: '140px' }}
            value={typeFilter}
            onChange={(value) => setTypeFilter(value)}
          >
            <Option
              key="any"
              value={null}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(eventTypes, (eventType) => (
              <Option
                key={eventType.type}
                value={eventType.type}
                className="text-xs flex items-center"
              >
                {t(eventType.name)}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <strong>{t('location')}:</strong>
          <br />
          <Select
            loading={loadingLocations}
            disabled={loadingLocations}
            style={{ width: '140px' }}
            value={locationFilter}
            onChange={(value) => setLocationFilter(value)}
          >
            <Option
              key="any"
              value={null}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(locations, (location) => (
              <Option
                key={location.id}
                value={location.id}
                className="text-xs flex items-center"
              >
                {location.name}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <strong>{t('host')}:</strong>
          <br />
          <Select
            loading={loadingHosts}
            disabled={loadingHosts}
            style={{ width: '140px' }}
            value={hostFilter}
            onChange={(value) => setHostFilter(value)}
          >
            <Option
              key="any"
              value={null}
              className="text-xs flex items-center"
            >
              {t('any')}
            </Option>
            {_.map(hosts, (host) => (
              <Option
                key={host.id}
                value={host.id}
                className="text-xs flex items-center"
              >
                {host.firstName} {host.lastName}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <strong>{t('dates')}:</strong>
          <RangePicker
            disabled={loading}
            format="MMM, DD YYYY"
            className="w-full"
            presets={dateRangePresets(t)}
            value={[
              dayjs(dates?.start),
              dayjs(dates?.end)
            ]}
            onChange={(date, dateString) => {
              setDates({
                start: dateString[0],
                end: dateString[1]
              });
            }}
          />
        </div>
        <div>
          <strong>{t('include_timeslots')}:</strong>
          <br />
          <Switch
            disabled={loading}
            checked={includeTimeslots}
            onChange={(checked) => {
              setIncludeTimeslots(checked);
            }}
          />
        </div>
        <div>
          <br />
          <Button
            type="primary"
            onClick={() => getData(false)}
          >
            {t('search')}
          </Button>
        </div>
      </div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_events')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && reportData.totalEvents.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_slots')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && reportData.totalSlots.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_bookings')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && reportData.totalBookings.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_payments')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && reportData.totalPayments.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('total_sales')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">${reportData && reportData.totalSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('fill_rate')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && `${percent(reportData.fillPercentage)}%`}</dd>
          </Spin>
        </div>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <Spin spinning={loading}>
            <dt className="text-sm font-medium text-gray-500 truncate">{t('vacancy_rate')}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{reportData && `${percent(reportData.vacancyPercentage)}%`}</dd>
          </Spin>
        </div>
      </dl>
      <br />
      <Button
        style={{ float: 'right' }}
        size="small"
        icon={<CloudDownloadOutlined />}
        onClick={() => {
          const data = _.map(reportData.events, (p) => ({
            eventName: p.eventName,
            totalBookings: p.totalBookings,
            totalPayments: p.totalPayments,
            totalSales: p.totalSales,
            totalSlots: p.totalSlots,
            fillPercentage: `${percent(p.fillPercentage)}%`,
            vacancyPercentage: `${percent(p.vacancyPercentage)}%`
          }));
          saveAsCsv({
            data,
            fields: eventListFields,
            filename: `Events ${moment(dates.start).format('MM/DD/YYYY')} - ${moment(dates.end).format('MM/DD/YYYY')}`,
          });
        }}
      >
        {t('export_list')}
      </Button>
      <br />
      <Table
        className="mt-2"
        pagination="none"
        size="small"
        bordered
        rowKey="eventName"
        loading={loading}
        columns={[
          {
            title: t('event_name'),
            key: 'eventName',
            dataIndex: 'eventName',
            align: 'left',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
            sorter: (a, b) => a.eventName - b.eventName,
          },
          {
            title: t('location'),
            key: 'locationName',
            dataIndex: 'locationName',
            align: 'left',
            className: 'text-xs',
            render: (row) => (
              <span>{row}</span>
            ),
            sorter: (a, b) => a.locationName - b.locationName,
          },
          {
            title: t('total_slots'),
            key: 'totalSlots',
            dataIndex: 'totalSlots',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalSlots - b.totalSlots,
          },
          {
            title: t('total_bookings'),
            key: 'totalBookings',
            dataIndex: 'totalBookings',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalBookings - b.totalBookings,
          },
          {
            title: t('total_confirmed'),
            key: 'totalConfirmed',
            dataIndex: 'totalConfirmed',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalConfirmed - b.totalConfirmed,
          },
          {
            title: t('total_pending'),
            key: 'totalPending',
            dataIndex: 'totalPending',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalPending - b.totalPending,
          },
          {
            title: t('total_payments'),
            key: 'totalPayments',
            dataIndex: 'totalPayments',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalPayments - b.totalPayments,
          },
          {
            title: t('total_sales'),
            key: 'totalSales',
            dataIndex: 'totalSales',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>${row.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            ),
            sorter: (a, b) => a.totalSales - b.totalSales,
          },
          {
            title: t('fill_rate'),
            key: 'fillPercentage',
            dataIndex: 'fillPercentage',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{percent(row)}%</span>
            ),
            sorter: (a, b) => a.fillPercentage - b.fillPercentage,
          },
          {
            title: t('vacancy_rate'),
            key: 'vacancyPercentage',
            dataIndex: 'vacancyPercentage',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>{percent(row)}%</span>
            ),
            sorter: (a, b) => a.vacancyPercentage - b.vacancyPercentage,
          },
          {
            key: 'details',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <span>
                <Button
                  icon={<SearchOutlined />}
                  size="small"
                  onClick={() => setViewDetails(row)}
                />
              </span>
            ),
          }
        ]}
        dataSource={reportData?.events}
        expandable={{
          expandRowByClick: false,
          rowExpandable: (record) => record?.timeslots?.length > 0,
          expandedRowRender: (record, index) => (
            <Table
              bordered
              key={index}
              size="small"
              dataSource={record.timeslots}
              columns={[
                {
                  title: t('start_date'),
                  key: 'startTime',
                  dataIndex: 'startTime',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <div className="flex flex-col">
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                    </div>
                  ),
                },
                {
                  title: t('end_date'),
                  key: 'endTime',
                  dataIndex: 'endTime',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <div className="flex flex-col">
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                      <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                    </div>
                  ),
                },
                {
                  title: t('capacity'),
                  key: 'maxCapacity',
                  dataIndex: 'maxCapacity',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  ),
                },
                {
                  title: t('total_bookings'),
                  key: 'totalBookings',
                  dataIndex: 'totalBookings',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  ),
                },
                {
                  title: t('total_payments'),
                  key: 'totalPayments',
                  dataIndex: 'totalPayments',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  ),
                },
                {
                  title: t('total_sales'),
                  key: 'totalSales',
                  dataIndex: 'totalSales',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{row.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  ),
                },
                {
                  title: t('fill_rate'),
                  key: 'fillPercentage',
                  dataIndex: 'fillPercentage',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{percent(row)}%</span>
                  ),
                },
                {
                  title: t('vacancy_rate'),
                  key: 'vacancyPercentage',
                  dataIndex: 'vacancyPercentage',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <span>{percent(row)}%</span>
                  ),
                },
              ]}
              expandable={{
                expandRowByClick: false,
                rowExpandable: (record2) => record2?.bookings?.length > 0,
                expandedRowRender: (record2, index2) => (
                  <div>
                    <br />
                    <Button
                      style={{ float: 'right' }}
                      size="small"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => {
                        const data = _.map(record2.bookings, (p) => ({
                          createdAt: moment(`${p.createdAt}+0000`)
                            .tz(timezone)
                            .format('MM/DD/YYYY h:mm a'),
                          userFirstName: p.userFirstName,
                          userLastName: p.userLastName,
                          status: getEventBookingStatus(p.status),
                          userPhone: formatPhoneNumber(p.userPhone),
                          userEmail: p.userEmail
                        }));
                        saveAsCsv({
                          data,
                          fields: participantsListFields,
                          filename: 'Event Participants',
                        });
                      }}
                    >
                      {t('export_list')}
                    </Button>
                    <br />
                    <Table
                      bordered
                      rowKey="id"
                      size="small"
                      dataSource={record2.bookings}
                      columns={[
                        {
                          title: t('created_on'),
                          key: 'createdAt',
                          dataIndex: 'createdAt',
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <div className="flex flex-col">
                              <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('ddd')}</span>
                              <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('MMM D')}</span>
                              <span>{moment(row).add(envConfig.REACT_APP_TIMEZONE_OFFSET, 'hours').format('h:mm A')}</span>
                            </div>
                          ),
                        },
                        {
                          title: t('status'),
                          key: 'status',
                          dataIndex: 'status',
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <span>{t(getEventBookingStatus(row))}</span>
                          ),
                        },
                        {
                          title: t('name'),
                          key: 'userFirstName',
                          align: 'center',
                          className: 'text-xs',
                          render: (row) => (
                            <span>{`${row.userFirstName} ${row.userLastName}`.trim()}</span>
                          ),
                        }
                      ]}
                    />
                  </div>
                )
              }}
            />
          )
        }}
      />
      {viewDetails ? <EventDetails /> : null }
    </>
  );
}
export default BookingsHistory;
