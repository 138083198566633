import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useMount } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Card,
  Modal,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ReloadOutlined,
} from '@ant-design/icons';
import {
  timezoneAtom,
  profileAtom,
  giftCardHistoryAtom,
  selectedOrderAtom,
  viewDetailsAtom
} from '../../../../atoms/Atoms';
import {
  formatPhoneNumber,
  toCurrency,
  mapOrderType,
  mapOrderStatus,
  thousand,
  renderShortDateTime,
  renderDate,
  mapPaymentType,
  renderShortDateHour
} from '../../../utils/functions';
import api from '../../../../api/api';
import { dateRangePresets } from '../../../utils/utils';
import OrderDetailsModal from '../../../shared-components/modals/OrderDetailsModal';

function GiftCardsHistoryModal() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [selectedGiftCard, setSelectedGiftCard] = useRecoilState(giftCardHistoryAtom);
  const setSelectedOrder = useSetRecoilState(selectedOrderAtom);
  const setViewDetails = useSetRecoilState(viewDetailsAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  function getTransactionType(type) {
    if (type === 1) {
      return t('redemption');
    }
    return t('load');
  }

  function loadOrder(orderId) {
    setLoadingOrder(true);
    let tempReportData = JSON.parse(JSON.stringify(reportData));
    for (let i = 0; i < tempReportData.transactions.length; i++) {
      const transaction = tempReportData.transactions[i];
      if (transaction.orderId === orderId) {
        transaction.loading = true;
      }
    }
    setReportData(tempReportData);
    api
      .get(`orders/order/${orderId}`)
      .then((response) => {
        setLoadingOrder(false);
        const order = response.data.data;
        tempReportData = JSON.parse(JSON.stringify(reportData));
        for (let i = 0; i < tempReportData.transactions.length; i++) {
          const transaction = tempReportData.transactions[i];
          transaction.loading = false;
        }
        setReportData(tempReportData);
        setViewDetails(true);
        setSelectedOrder({
          ...order,
          key: order.id,
          id: order.id,
          itemCount: order.itemCount,
          storeId: order.businessId,
          locationId: order.locationId,
          orderType: order.type,
          deliveryZone: order.deliveryZoneName,
          createdAt: renderShortDateTime(order.actualTime, timezone),
          cancelledAt: renderShortDateTime(order.cancellationDate, timezone),
          createdDiff: moment(`${order.createdAt}+0000`).tz(timezone).fromNow(),
          dispatchDiff:
            order.dispatchDateTime == null
              ? 'N/A'
              : moment(`${order.dispatchDateTime}+0000`).tz(timezone).fromNow(),
          businessName: order.businessName,
          type: t(mapOrderType(order.type)),
          typeEnum: order.type,
          confirmationCode: order.confirmationCode,
          customer: order.customerName,
          customerId: order.customerId,
          customerPhone: formatPhoneNumber(order.customerPhone),
          customerLocationId: order.customerLocationId,
          ivuCN: order.ivuControlNumber === null ? 'N/A' : order.ivuControlNumber,
          statusText: mapOrderStatus(order.status),
          cardNickname: order.cardNickname,
          nickName: order.nickName,
          cardholderName: order.cardholderName,
          status: order.status,
          paymentTypeText: t(mapPaymentType(order.paymentType)),
          paymentType: order.paymentType,
          desiredDateTime: renderShortDateHour(order.desiredDateTime, timezone),
          date: renderShortDateHour(order.date, timezone),
          dDateTime: order.desiredDateTime,
          dispatchDateTime: renderDate(order.dispatchDateTime, timezone),
          total: order.orderTotal,
          orderTotal: order.orderTotal,
          subTotal: order.productsTotal,
          munTax: order.municipalTax,
          stateTax: order.stateTax,
          deliveryFee: order.deliveryTotal,
          pickupFee: order.processingFee,
          transferTotal: order.transferTotal,
          processingFee: order.processingFee,
          transferCompletedText: order.transferCompleted ? 'Yes' : 'No',
          transferCompleted: order.transferCompleted,
          authTotal: order.authTotal,
          returnTotal: order.returnTotal,
          credit:
            order.authTotal - order.orderTotal
              ? (order.authTotal - order.orderTotal)
              : '0.00',
          referenceNumber:
            order.referenceNumber || order.referenceNumber !== ''
              ? order.referenceNumber
              : 'n/a',
          refNumAvailable: order.referenceNumber !== '' && order.referenceNumber !== null,
          discountAmount: order.discountAmount,
          businessDescription: order.businessDescription,
          returnNeeded: order.returnNeeded ? 'Yes' : 'No',
          returnCompleted: order.returnCompleted ? 'Yes' : 'No',
          athReturnError: order.athReturnError ? 'Yes' : 'No',
          wasAlreadyCharged: order.hasBeenCharged ? 'Yes' : 'No',
          wasAlreadyAuthed: order.hasBeenAuthed ? 'Yes' : 'No',
          hasBeenCharged: order.hasBeenCharged,
          hasBeenAuthed: order.hasBeenAuthed,
          items: order.items,
          signatureImageUrl: order.signatureImageUrl,
          ebtReceiptUrl: order.ebtReceiptUrl,
          pickUpName: order.pickUpName,
          customerLocation: order.customerLocation,
          paymentTender: order.paymentTender,
          posLaneTag: order.posLaneTag,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoadingOrder(false);
      });
  }

  const width = window.innerWidth;
  const allColumns = [{
    title: t('day'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{renderShortDateTime(text, timezone)}</span>,
  },
  {
    title: t('transaction'),
    dataIndex: 'transactionType',
    key: 'transactionType',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{getTransactionType(text)}</span>,
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{thousand(text)}</span>,
  },
  {
    title: t('final_balance'),
    dataIndex: 'giftCardCurrentBalance',
    key: 'giftCardCurrentBalance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{thousand(text)}</span>,
  },
  {
    title: t('order'),
    key: 'orderConfirmationCode',
    align: 'right',
    className: 'text-xs',
    render: (row) => (
      <Button
        size="small"
        type="primary"
        loading={row.loading}
        disabled={loadingOrder}
        onClick={() => loadOrder(row.orderId)}
      >
        {row.orderConfirmationCode}
      </Button>
    ),
  },
  {
    title: t('business'),
    dataIndex: 'orderBusinessName',
    key: 'orderBusinessName',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  }
  ];

  function getData(page) {
    setLoading(true);
    const data = {
      Page: page,
      Size: currentSize
    };
    api
      .post(
        `giftcards/history/${selectedGiftCard?.id}`,
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (selectedGiftCard) {
      setCurrentPage(0);
      getData(0);
    } else {
      setReportData(null);
    }
  }, [currentSize, selectedGiftCard]);

  useEffect(() => {
    setViewDetails(false);
  }, []);

  return (
    <>
      <Modal
        width={600}
        title={`${t('gift_card')} - ${selectedGiftCard?.code}`}
        open={selectedGiftCard}
        onCancel={() => {
          setSelectedGiftCard(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setSelectedGiftCard(null);
            }}
          >
            {t('close')}
          </Button>
        ]}
      >
        <PageHeader
          className="mb-4 mt-4 px-0"
          title={t('history')}
          extra={
            [
              <div className="space-x-2">
                <br className={width < 1024 ? null : 'hidden'} />
                <Button
                  type="primary"
                  size="small"
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    setCurrentPage(0);
                    getData(0);
                  }}
                >
                  {t('refresh')}
                </Button>
              </div>
            ]
          }
        />
        <Table
          loading={loading}
          bordered
          pagination={{
            pageSize: currentSize,
            showSizeChanger: true,
            defaultCurrent: 0,
            current: currentPage + 1,
            total: reportData?.total,
            onChange: (page, pageSize) => {
              if (page - 1 !== currentPage) {
                setCurrentPage(page - 1);
                getData(page - 1);
              } else {
                setCurrentSize(pageSize);
              }
            }
          }}
          columns={allColumns}
          dataSource={reportData?.transactions}
        />
      </Modal>
      <OrderDetailsModal clearOrderOnClose />
    </>
  );
}

export default GiftCardsHistoryModal;
