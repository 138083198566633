import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Card,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ReloadOutlined,
} from '@ant-design/icons';
import { timezoneAtom, profileAtom } from '../../../../atoms/Atoms';
import { thousandv2, toCurrency, renderShortDateTime } from '../../../utils/functions';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import { dateRangePresets } from '../../../utils/utils';

function GiftCardsHistory() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { saveAsCsv } = useJsonToCsv();
  const timezone = useRecoilValue(timezoneAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(20);

  const isAdmin =
    profile.contents && profile?.contents?.roles?.includes('SuperAdmin');
  const isStoreManager =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('StoreAdmin') ||
        profile?.contents?.roles?.includes('Owner')));
  const canViewFinance =
    isAdmin ||
    isStoreManager ||
    (profile.contents &&
      (profile.contents.roles.includes('Finance') ||
        profile.contents.roles.includes('TopAnalytics')));
  const [reportData, setReportData] = useState(null);
  const [accountPayableData, setAccountPayableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAccountPayable, setLoadingAccountPayable] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD')
  });

  function getTransactionType(type) {
    if (type === 1) {
      return t('redemption');
    }
    return t('load');
  }

  const width = window.innerWidth;
  const allColumns = [{
    title: t('day'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{renderShortDateTime(text, timezone)}</span>,
  },
  {
    title: t('code'),
    dataIndex: 'giftCardCode',
    key: 'giftCardCode',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('transaction'),
    dataIndex: 'transactionType',
    key: 'transactionType',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{getTransactionType(text)}</span>,
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('current_balance'),
    dataIndex: 'giftCardCurrentBalance',
    key: 'giftCardCurrentBalance',
    align: 'center',
    className: 'text-xs',
    render: (text) => <span>{toCurrency(text)}</span>,
  },
  {
    title: t('order'),
    dataIndex: 'orderConfirmationCode',
    key: 'orderConfirmationCode',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  },
  {
    title: t('business'),
    dataIndex: 'orderBusinessName',
    key: 'orderBusinessName',
    align: 'right',
    className: 'text-xs',
    render: (text) => <span>{text}</span>,
  }];

  function getData(page) {
    setLoading(true);
    const data = {
      StartDate: dayjs(dateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(dateRange.end).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      Page: page,
      Size: currentSize
    };
    api
      .post(
        'giftcards/history',
        data
      )
      .then((response) => {
        setLoading(false);
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getAccountPayable() {
    setLoadingAccountPayable(true);
    api
      .get('giftcards/accountpayable')
      .then((response) => {
        setLoadingAccountPayable(false);
        setAccountPayableData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        setLoadingAccountPayable(false);
      });
  }

  useMount(() => {
    setCurrentPage(0);
    getData(0);
    getAccountPayable();
  });

  useEffect(() => {
    setCurrentPage(0);
    getData(0);
  }, [dateRange, currentSize]);

  return (
    <div>
      <PageHeader
        className="mb-4 px-0"
        title={t('history')}
        extra={
          [
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dateRange?.start),
                dayjs(dateRange?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                setDateRange({
                  start: dateString[0],
                  end: dateString[1]
                });
              }}
            />,
            <div className="space-x-2">
              <br className={width < 1024 ? null : 'hidden'} />
              <Button
                type="primary"
                size="small"
                loading={loading || loadingAccountPayable}
                disabled={loading || loadingAccountPayable}
                icon={<ReloadOutlined />}
                onClick={() => {
                  setCurrentPage(0);
                  getData(0);
                  getAccountPayable();
                }}
              >
                {t('refresh')}
              </Button>
            </div>
          ]
        }
      />
      <div className="divide-y space-y-4 mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          <Card
            type="inner"
            title={t('total_active_cards')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loadingAccountPayable}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {thousandv2(accountPayableData?.totalActiveGiftCards, true)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('account_payable')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loadingAccountPayable}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {toCurrency(accountPayableData?.accountPayable)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('purchased')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {toCurrency(reportData?.purchased)}</span>
            </Card.Grid>
          </Card>
          <Card
            type="inner"
            title={t('redeemed')}
            className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
            hoverable
            loading={loading}
          >
            <Card.Grid
              className="bg-white"
              hoverable={false}
              style={bottomRowGridStyle}
            >
              <span> {toCurrency(reportData?.cashSalesTotal)}</span>
            </Card.Grid>
          </Card>
        </dl>
      </div>
      <Table
        loading={loading}
        bordered
        pagination={{
          pageSize: currentSize,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: reportData?.total,
          onChange: (page, pageSize) => {
            if (page - 1 !== currentPage) {
              setCurrentPage(page - 1);
              getData(page - 1);
            } else {
              setCurrentSize(pageSize);
            }
          }
        }}
        columns={allColumns}
        dataSource={reportData?.transactions}
      />
    </div>
  );
}

export default GiftCardsHistory;
