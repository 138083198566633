import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import HomeHeader from '../../shared-components/header/HomeHeader';
import RegisterForm from './shared-components/RegisterForm';
import { profileAtom } from '../../../atoms/Atoms';

function Register() {
  const user = useRecoilValue(profileAtom);
  const history = useHistory();
  if (user) {
    history.push('/');
  }
  return (
    <div>
      <HomeHeader />
      <RegisterForm />
    </div>
  );
}

export default Register;
