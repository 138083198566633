import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, } from 'recoil';
import { Modal, Button, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import _ from 'lodash';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { openDeliveyMapAtom, mapZoomAtom, selectedOrderAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';

function DeliveryMap() {
  const [open, setOpen] = useRecoilState(openDeliveyMapAtom);
  const [loading, setLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomAtom);
  const [map, setMap] = useState(null);
  const { t, i18n } = useTranslation();
  const order = useRecoilValue(selectedOrderAtom);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.REACT_APP_GEO_API_KEY,
    language: 'es-US',
    libraries: ['places']
  });

  const onLoad = React.useCallback((tempMap) => {
    const bounds = new window.google.maps.LatLngBounds();
    tempMap.fitBounds(bounds);
    setMap(tempMap);
  }, []);

  const onUnmount = React.useCallback((tempMap) => {
    setMap(null);
  }, []);

  return (
    <Modal
      closable="true"
      centered="true"
      open={open}
      onCancel={() => { setOpen(false); }}
      footer={[
        <Button
          key="close"
          disabled={loading}
          onClick={() => setOpen(false)}
        >
          {t('close')}
        </Button>
      ]}
    >
      <PageHeader title={t('delivery_map')} />
      <Spin spinning={loading}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{
              height: '400px',
              width: '100%'
            }}
            zoom={zoomLevel}
            onLoad={onLoad}
            onUnmount={onUnmount}
            center={{
              lat: order?.customerLocationLat,
              lng: order?.customerLocationLon
            }}
          >
            <Marker
              position={{ lat: order?.customerLocationLat, lng: order?.customerLocationLon }}
            />
          </GoogleMap>
        )}
      </Spin>
    </Modal>
  );
}

export default DeliveryMap;
