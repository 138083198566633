import React, { useRef, useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { Button, Spin, Col, Row, Typography, Modal, Image } from 'antd';
import { PrinterOutlined, PushpinOutlined, SearchOutlined } from '@ant-design/icons';
import { businessAtom, openDeliveyMapAtom, selectedOrderAtom, viewDetailsAtom } from '../../../atoms/Atoms';
import { formatPhoneNumber, getCardType, getUserPlatform } from '../../utils/functions';
import api from '../../../api/api';
import DeliveryMap from './DeliveryMap';

function Order({ clearOrderOnClose }) {
  const ref = useRef(null);
  const [order, setOrder] = useRecoilState(selectedOrderAtom);
  const [loadingItems, setLoadingItems] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const { t } = useTranslation();
  const setOpenMap = useSetRecoilState(openDeliveyMapAtom);
  const [viewDetails, setViewDetails] = useRecoilState(viewDetailsAtom);
  const [loading, setLoading] = useState(false);
  const [visibleSignature, setVisibleSignature] = useState(false);
  const [visibleSignatureImg, setVisibleSignatureImage] = useState(null);
  const [visibleEbt, setVisibleEbt] = useState(false);
  const [visibleEbtImg, setVisibleEbtImage] = useState(null);
  const [scaleStep, setScaleStep] = useState(0.5);

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      gtins: p.gtins,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      type: p.type,
      sides: p.sides,
      addOns: p.addOns,
      specialInstructions: p.specialInstruction
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  // useEffect(() => {
  //   if (orderItems) {
  //     const temp = _.filter(orderItems,
  //       (s) => s.type !== 2);
  //     setOrderItems(temp);
  //   }
  // }, [orderItems]);

  function loadItems() {
    setLoadingItems(true);
    api
      .get(`orders/${order?.id}/items`)
      .then((response) => {
        if (response?.data?.success) {
          const map = mapOrderItems(response.data.data.results);
          setOrderItems(map);
        }
        setLoadingItems(false);
      })
      .catch((error) => {
        setLoadingItems(false);
        console.error(error);
      });
  }

  useMount(() => {
    setOrderItems([]);
    loadItems();
  });

  useEffect(() => {
    loadItems();
  }, [order?.id]);

  return (
    <Modal
      closable="true"
      centered="true"
      open={viewDetails && order}
      width="95%"
      onCancel={() => {
        setViewDetails(false);
        setOrderItems([]);
        if (clearOrderOnClose) {
          setOrder(null);
        }
      }}
      footer={[
        <Button
          key="close"
          disabled={loading}
          onClick={() => {
            setViewDetails(false);
            setOrderItems([]);
            if (clearOrderOnClose) {
              setOrder(null);
            }
          }}
        >
          {t('close')}
        </Button>
      ]}
    >
      <div>
        <main ref={ref} className="max-w-7xl mx-auto px-4 py-8 space-y-8">
          <section className="text-3xl font-extrabold tracking-tight">
            {t('order_details')}
          </section>
          <section className="flex items-center justify-between text-sm border-b border-gray-200 pb-5 sm:flex sm:justify-between">
            <Col>
              <Row>
                <Typography.Text className="font-bold">{t('service')}</Typography.Text>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('type')}:</div>
                <div className="font-medium">{order?.type}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('contact')}:</div>
                <div className="font-medium">{order?.customerPhone}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('customer')}:</div>
                <div className="font-medium">{order?.customer}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('confirmation_code')}:</div>
                <div className="font-medium">{order?.confirmationCode}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('date')}:</div>
                <div className="font-medium">{order?.desiredDateTime}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('store')}:</div>
                <div className="font-medium">{order?.businessName}</div>
              </Row>
            </Col>
            <Col hidden={order?.type !== 'Delivery'}>
              <Row>
                <Typography.Text className="font-bold">{t('location')}</Typography.Text>
              </Row>
              <Row>
                <div className="font-medium">{order?.customerLocationName}</div>
              </Row>
              <Row>
                <div className="font-medium">{order?.customerLocationAddress}</div>
              </Row>
              <Row>
                <div className="font-medium">{`${order?.customerLocationCity}, ${order?.customerLocationZipcode}`}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('instructions')}</div>
              </Row>
              <Row>
                <div className="font-medium">{`${order?.customerLocationInstructions}`}</div>
              </Row>
              <Row hidden>
                <div className="text-gray-500 mr-1">{t('platform')}:</div>
                <div className="font-medium">{getUserPlatform(order?.userPlatform)}</div>
              </Row>
              <Row hidden>
                <div className="text-gray-500 mr-1">{t('zone')}:</div>
                <div className="font-medium">{order?.deliveryZoneName}</div>
              </Row>
              <Row>
                <div className="text-gray-500 mr-1">{t('pickup_name')}:</div>
                <div className="font-medium">{order?.pickUpName}</div>
              </Row>
            </Col>
            <Col>
              <Row>
                <Button
                  type="primary"
                  disabled={order?.signatureImageUrl === '' || order?.signatureImageUrl == null}
                  onClick={() => {
                    setVisibleSignature(true);
                    setVisibleSignatureImage(order.signatureImageUrl);
                  }}
                >
                  {t('signature')}
                </Button>
              </Row>
              <br />
              <Row>
                <Button
                  type="primary"
                  disabled={order?.ebtReceiptUrl === '' || order?.ebtReceiptUrl == null}
                  onClick={() => {
                    setVisibleEbt(true);
                    setVisibleEbtImage(order.ebtReceiptUrl);
                  }}
                >
                  EBT
                </Button>
              </Row>
            </Col>
            <div>
              <ReactToPrint
                content={() => ref.current}
                trigger={() => (
                  <button
                    type="button"
                    className="flex items-center text-white text-xl justify-center bg-blue-400 hover:bg-blue-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                  >
                    <PrinterOutlined className="h-5 w-5" />
                  </button>
                )}
              />
              <Button
                hidden={order?.type !== 'Delivery'}
                className="flex items-center text-white mt-4 text-xl justify-center bg-green-400 hover:bg-green-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in"
                type="link"
                onClick={() => {
                  setOpenMap(true);
                }}
              >
                <PushpinOutlined className="h-5 w-5" />
              </Button>
            </div>
          </section>
          <section className="space-y-4">
            <div className="text-xl font-extrabold tracking-tight">
              {t('items')} ({t('included')}): {_.filter(orderItems, (o) => !o.isVoided).length}
              <br />
              {loadingItems ? <Spin /> : null }
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:md:grid-cols-4 gap-4">
              {_.map(_.filter(orderItems, (o) => !o.isVoided), (product) => (
                <div
                  key={product.id}
                  className="flex items-center border rounded-xl space-x-4 p-4"
                >
                  <div className="flex items-center justify-center w-20 h-20 border rounded-xl p-2">
                    <img
                      src={product.itemImage}
                      alt={product.name}
                      className="object-center object-cover"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-xs font-medium">{product.name}</div>
                    <div className="text-xs text-gray-500">{product.brand}</div>
                    <div className="text-xs text-gray-500">
                      {product.description}
                    </div>
                    <div className="text-xs font-medium">{product?.specialInstructions}</div>
                  </div>
                  <br />
                  <div>
                    {_.map(product.addOns, (addOns) => (
                      <div className="flex-1">
                        <div className="text-xs font-medium">{addOns?.completeName}</div>
                        <div className="text-xs text-gray-500">{addOns?.price}</div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col text-sm font-medium">
                    <div className="flex flex-row">
                      <div className="text-xs text-gray-600">x</div>
                      <div className="text-sm">{product.amount.toFixed(2)}</div>
                      <div className="text-sm lowercase">{product.hasRandomWeight ? product.weightDescriptor : ''}</div>
                    </div>
                    <div>${product.price.toFixed(2)}</div>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {_.filter(orderItems, (o) => o.isVoided).length > 0 && (
            <section className="space-y-4">
              <div className="text-xl font-extrabold tracking-tight">
                {t('items')} ({t('not_included')}): {_.filter(orderItems, (o) => o.isVoided).length}
                <br />
                {loadingItems ? <Spin /> : null }
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:md:grid-cols-4 gap-4">
                {_.map(_.filter(orderItems, (o) => o.isVoided), (product) => (
                  <div
                    key={product.id}
                    className="flex items-center border rounded-xl space-x-4 p-4"
                  >
                    <div className="flex items-center justify-center w-20 h-20 border rounded-xl p-2">
                      <img
                        src={product.itemImage}
                        alt={product.name}
                        className="object-center object-cover"
                      />
                    </div>
                    <div className="flex-1">
                      <div className="text-xs font-medium">{product.name}</div>
                      <div className="text-xs text-gray-500">{product.brand}</div>
                      <div className="text-xs text-gray-500">
                        {product.description}
                      </div>
                      <div className="text-xs font-medium">{product?.specialInstructions}</div>
                    </div>
                    <br />
                    <div>
                      {_.map(product.addOns, (addOns) => (
                        <div className="flex-1">
                          <div className="text-xs font-medium">{addOns?.completeName}</div>
                          <div className="text-xs text-gray-500">{addOns?.price}</div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col text-sm font-medium">
                      <div className="flex flex-row">
                        <div className="text-xs text-gray-600">x</div>
                        <div className="text-sm">{product.amount.toFixed(2)}</div>
                        <div className="text-sm lowercase">{product.hasRandomWeight ? product.weightDescriptor : ''}</div>
                      </div>
                      <div>${product.price.toFixed(2)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
          <section>
            <div className="bg-gray-50 rounded-xl py-6 px-6 lg:px-0 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
              <dl className="grid grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:pl-8 lg:col-span-5">
                <div>
                  <dt className="font-medium text-gray-900">{t('store')}</dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">{order?.businessName}</span>
                    <span className="block">
                      {t('business_phone')}: {formatPhoneNumber(order?.businessPhone)}
                    </span>
                    <span className="block">
                      {t('order_service')}:{' '}
                      {order?.type === 0 ? t('pickup') : t('delivery')}
                    </span>
                    <span className="block">
                      {t('user_platform')}: {getUserPlatform(order?.userPlatform)}
                    </span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    {t('payment_information')}
                  </dt>
                  <dd className="mt-3 flex">
                    <div>
                      <img
                        alt={
                          order?.nickName ? order?.nickName : order?.paymentName
                        }
                        src={
                        order?.nickName
                          ? getCardType(order?.brand)
                          : order?.paymentImageUrl
                        }
                        className="h-4 w-auto"
                      />
                      <p className="sr-only">
                        {order?.nickName ? order?.nickName : order?.paymentName}
                      </p>
                    </div>
                    <div className="ml-4">
                      {order?.nickName ? order?.nickName : order?.paymentName}
                    </div>
                  </dd>
                </div>
              </dl>
              <dl className="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:pr-8 lg:col-span-7">
                <div className="pb-4 flex items-center justify-between">
                  <dt className="text-gray-600">Subtotal</dt>
                  <dd className="font-medium text-gray-900">
                    ${parseFloat(order?.originalSubtotal ?? order?.productsTotal).toFixed(2)}
                  </dd>
                </div>
                <div className="py-4 flex items-center justify-between">
                  <dt className="text-gray-600">{t('discount')}</dt>
                  <dd className="font-medium text-red-500">
                    ${parseFloat(order?.discountAmount).toFixed(2)}
                  </dd>
                </div>
                <div className="py-4 flex items-center justify-between">
                  <dt className="text-gray-600">{t('municipal_tax')}</dt>
                  <dd className="font-medium text-gray-900">
                    ${parseFloat(order?.munTax).toFixed(2)}
                  </dd>
                </div>
                <div className="py-4 flex items-center justify-between">
                  <dt className="text-gray-600">{t('state_tax')}</dt>
                  <dd className="font-medium text-gray-900">
                    ${parseFloat(order?.stateTax).toFixed(2)}
                  </dd>
                </div>
                <div className="py-4 flex items-center justify-between">
                  <dt className="text-gray-600">{t('service_cost')}</dt>
                  <dd className="font-medium text-gray-900">
                    ${(parseFloat(order?.processingFee) + order?.deliveryTotal).toFixed(2)}
                  </dd>
                </div>
                <div className="pt-4 flex items-center justify-between">
                  <dt className="font-medium text-gray-900">Total</dt>
                  <dd className="font-bold">
                    ${parseFloat(order?.total).toFixed(2)}
                  </dd>
                </div>
              </dl>
            </div>
            <DeliveryMap />
          </section>
        </main>
        <Image
          width={200}
          style={{ display: 'none' }}
          src={visibleSignatureImg}
          preview={{
            visible: visibleSignature,
            scaleStep,
            src: visibleSignatureImg,
            onVisibleChange: (value) => {
              setVisibleSignature(value);
              setVisibleSignatureImage(null);
            },
          }}
        />
        <Image
          width={200}
          style={{ display: 'none' }}
          src={visibleEbtImg}
          preview={{
            visible: visibleEbt,
            scaleStep,
            src: visibleSignatureImg,
            onVisibleChange: (value) => {
              setVisibleEbt(value);
              setVisibleEbtImage(null);
            },
          }}
        />
      </div>
    </Modal>
  );
}

export default Order;
