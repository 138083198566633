import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import GiftCardsHistory from './shared-components/GiftCardsHistory';
import GiftCardsSearch from './shared-components/GiftCardsSearch';

function GiftCardsReport() {
  const { t } = useTranslation();

  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t('cards')} key="1">
          <GiftCardsSearch />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('history')} key="2">
          <GiftCardsHistory />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default GiftCardsReport;
